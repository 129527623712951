import { XHR, Response } from '@/utils/xhr'
import { session, localstore } from '@/utils/localstorage'
import { config } from '@/conf'

const users = {
  // xhr class
  __xhr: new XHR(),
  // indicates whether the user's is authorized
  authorized: false,

  // registers new user
  register (email: string, oauth_token: string|undefined = undefined, turnstile_token: string|undefined = undefined): Promise<string> {
    return new Promise((resolve, reject) => {
      // build api path and init with email
      let path = '/register?email='+email

      // set currency
      const currency = localstore.get('currency')
      path += '&currency=' + (currency ? currency : 'eur')

      // check for user gclid
      const gclid = localstore.get('gclid')
      if (gclid !== undefined) {
        path += '&gclid='+gclid
      }

      // set language
      path += '&lang=' + config.lang.iso

      // check for trial only
      const trial_only = localstore.get('t')
      if (trial_only !== undefined && trial_only === '1') {
        path += '&d='+1
      }

      // handle oauth
      if (oauth_token !== undefined) {
        path += '&token='+oauth_token
      }
      if (turnstile_token) {
        path += '&turnstile='+turnstile_token
      }

      // exec api call
      this.__xhr.get(path).then((r: Response) => {
        this.authorized = true
        // save user_email
        localstore.set('user_email', email)
        resolve(r.data!)
      }).catch((e: number) => {
        this.authorized = false
        reject(e)
      })
    })
  },

  // registers new user via google oauth
  oauth_register (token: string): Promise<string> {
    const oauth_jwt = decode_jwt(token)
    return this.register(oauth_jwt['email'], token)
  },

  // login user via google oauth
  oauth_login (token: string): Promise<string> {
    const oauth_jwt = decode_jwt(token)
    return this.login(oauth_jwt['email'], '', token)
  },

  // user login process
  login (email: string, pass: string, oauth_token: string|undefined = undefined, turnstile_token: string|undefined = undefined): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = '/login?email='+email+'&pass='+pass
      if (oauth_token) {
        url += '&token='+oauth_token
      }
      if (turnstile_token) {
        url += '&turnstile='+turnstile_token
      }
      this.__xhr.get(url).then((r: Response) => {
        resolve(r.data)
        this.authorized = true
      }).catch((e: number) => {
        this.authorized = false
        reject(e)
      })
    })
  },

  // user login process
  verify_session (): Promise<string> {
    return new Promise((resolve, reject) => {
      this.__xhr.get('/session').then((r: Response) => {
        this.authorized = true
        resolve(r.data!)
      }).catch((e: number) => {
        this.authorized = false
        reject(e)
      })
    })
  },

  // user pass-reset process
  reset_pass (email: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.__xhr.get('/reset-pass?email='+email).then((r: Response) => {
        resolve(r.data)
      }).catch((e: number) => {
        reject(e)
      })
    })
  },

  // updates user billing
  update_billing (data: BillingInfo): Promise<any> {
    return new Promise((resolve, reject) => {
      this.__xhr.get('/card/update'+format_billing_info(data)).then((r: Response) => {
        resolve(r.data)
      }).catch((e: number) => {
        reject(e)
      })
    })
  },

  // checks whether user has supplied billing info
  check_billing (): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.__xhr.get('/cards/check-billing').then((r: Response) => {
        resolve(r.data === "true")
      }).catch((e: number) => {
        reject(e)
      })
    })
  },

  set_logged_in ()  {
    localstore.set('l', 1)
  },

  unset_logged_in ()  {
    localstore.set('l', 0)
  },

  is_logged_in () :boolean {
    return localstore.get('l') === '1'
  }
}

interface BillingInfo {
  first_name: string
  last_name: string
  address: string
  country: string
  city: string
  area_code: string
}

const format_billing_info = (data: BillingInfo): string => {
  return `?first_name=${data.first_name}&last_name=${data.last_name}&address=${data.address}&country=${data.country}&city=${data.city}&area_code=${data.area_code}`
}

const decode_jwt = (token: string): any => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

export { users, BillingInfo }
