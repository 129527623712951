class Modal {
  component?     : object
  props          : object = {}
  on_activate?   : (props?: object) => void
  on_deactivate? : (props?: object) => void
  on_close?      : () => void

  show (component: object, props: object = {}) {
    this.component = component
    this.props = props
    if (this.on_activate) this.on_activate()
    setTimeout(() => {
      document.body.style.overflow = 'hidden';
    }, 250)
    //
  }

  destroy () {
    document.body.style.overflow = 'initial';
    if (this.component === undefined) { return }
    this.component = undefined
    this.props = {}
    if (this.on_deactivate) this.on_deactivate()
  }

  close () {
    this.destroy()
    if (this.on_close) this.on_close()
  }
}

const modal = new Modal()

export {
  modal
}
